export default [
    {
        path: '/',
        redirect: '/dashboard',
    },
    // login page
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/auth/Login.vue'),
        meta: {
            guard: 'guest',
        }
    },
    // sigup page
    {
        path: '/register',
        name: 'Register',
        component: () => import('../views/auth/Register.vue'),
        meta: {
            guard: 'guest',
        }
    },
    // verify email user
    {
        path: '/seller-email/verify',
        name: 'user-verify',
        component: () => import('../views/auth/Verification.vue'),
        meta: {
            guard: 'auth',
        }
    },
    // verify email store
    {
        path: '/merchant-email/verify',
        name: 'merchant-verify',
        component: () => import('../views/auth/Verification.vue'),
        meta: {
            guard: 'auth',
        }
    },
    // send reset password
    {
        path: '/forgot-password',
        name: 'password',
        component: () => import('../views/auth/ForgotPassword.vue'),
        meta: {
            guard: 'guest',
        }
    },
    // reset password
    {
        path: '/password/reset',
        name: 'reset-password',
        component: () => import('../views/auth/ResetPassword.vue'),
        meta: {
            guard: '',
        }
    },
    {
        path: '/',
        component: () => import('@/components/layouts/Index.vue'),
        children: [
            {
                path: '/dashboard',
                name: 'home',
                component: () => import('../views/Home.vue'),
                meta: {
                    guard: 'auth',
                }
            },
            // product page
            {
                path: '/products',
                name: 'products',
                component: () => import('../views/products/Index.vue'),
                meta: {
                    guard: 'auth'
                },
            },
            // product detail
            {
                path: '/product/:id/detail',
                name: 'product',
                component: () => import('../views/products/product/nonbook/Id.vue'),
                meta: {
                    guard: 'auth',
                }
            },
            // product book detail
            {
                path: '/product/book/:id/detail',
                name: 'product-book',
                component: () => import('../views/products/product/book/Id.vue'),
                meta: {
                    guard: 'auth',
                }
            },
            // create product
            {
                path: '/product/new',
                name: 'product-new',
                component: () => import('../views/products/product/nonbook/New.vue'),
                meta: {
                    guard: 'auth',
                }
            },
            // create product book
            {
                path: '/product/book/new',
                name: 'book-new',
                component: () => import('../views/products/product/book/New.vue'),
                meta: {
                    guard: 'auth',
                }
            },
            // review product
            {
                path: '/product/:id/review',
                name: 'feedback',
                component: () => import('../views/products/Review.vue'),
                meta: {
                    guard: 'auth',
                }
            },
            // product storage
            {
                path: '/merchant-storages',
                name: 'etalase',
                component: () => import('../views/products/Etalase.vue'),
                meta: {
                    guard: 'auth',
                }
            },
            // order page
            {
                path: '/orders',
                name: 'orders',
                component: () => import('../views/order/Index.vue'),
                meta: {
                    guard: 'auth'
                }
            },
            // order detail
            {
              path: '/order/:id',
              redirect: (to) => {
                const { id } = to.params;
                return `/orders/${id}`;
              }
            },
            {
                path: '/orders/:id',
                name: 'order',
                component: () => import('../views/order/detail/Id.vue'),
                meta: {
                    guard: 'auth',
                }
            },
            {
                path: '/signature',
                name: 'signature',
                component: () => import('../views/signature/Index.vue'),
                meta: {
                    guard: 'auth'
                }
            },
            // {
            //     path: '/complain/:id',
            //     name: 'complain',
            //     component: () => import('../views/complain/Id.vue'),
            //     meta: {
            //         guard: 'auth',
            //     }
            // },

            // negotiation page
            {
                path: '/negotiations',
                name: 'negotiations',
                component: () => import('../views/negotiation/Index.vue'),
                meta: {
                    guard: 'auth'
                }
            },
            // negotiation detail
            {
                path: '/negotiation/:id',
                name: 'negotiation',
                component: () => import('../views/negotiation/Id.vue'),
                meta: {
                    guard: 'auth'
                }
            },
            // loan page
            {
                path: '/loans',
                name: 'loans',
                component: () => import('../views/loan/Index.vue'),
                meta: {
                    guard: 'auth'
                }
            },
            // compare page
            // {
            //     path: '/compares',
            //     name: 'compares',
            //     component: () => import('../views/compare/Index.vue'),
            //     meta: {
            //         guard: 'auth'
            //     }
            // },
            // log activity
            {
                path: '/log-activity',
                name: 'activities',
                component: () => import('../views/user/LogActivity.vue'),
                meta: {
                    guard: 'auth',
                }
            },
            // list user of merchant
            {
                path: '/users',
                name: 'users',
                component: () => import('../views/user/Index.vue'),
                meta: {
                    guard: 'auth:admin',
                }
            },
            // product discuss
            {
                path: '/products/chat',
                name: 'discuss',
                component: () => import('../views/discuss/Index.vue'),
                meta: {
                    guard: 'auth',
                }
            },
            // notification page
            {
                path: '/notification',
                name: 'notification',
                component: () => import('../views/notification/Index.vue'),
                meta: {
                    guard: 'auth',
                }
            },
            // store update
            {
                path: '/merchant/update',
                name: 'merchant-update',
                component: () => import('../views/merchant/Index.vue'),
                meta: {
                    guard: 'auth',
                }
            },
            // store info
            {
                path: '/merchant',
                name: 'merchant-info',
                component: () => import('../views/merchant/Info.vue'),
                meta: {
                    guard: 'auth',
                }
            },
            // store premium
            {
                path: '/merchant-premium',
                name: 'merchant-premium',
                component: () => import('../views/merchant/Premium.vue'),
                meta: {
                    guard: 'auth',
                }
            },
            // store review
            {
                path: '/merchant/reviews',
                name: 'merchant-reviews',
                component: () => import('../views/merchant/Review.vue'),
                meta: {
                    guard: 'auth',
                }
            },
            // product packages page
            {
                path: '/product-packages',
                name: 'product-packages',
                component: () => import('../views/product_packages/Index.vue'),
                meta: {
                    guard: 'auth'
                },
            },
        ]
    },
    {
      path: '/maintenance',
      name: 'maintenance',
      component: () => import('../views/Maintenance.vue'),
    },
    {
        path: '/404',
        name: '404',
        component: () => import('../views/404.vue')
    },
    {
        path: '*',
        redirect: '404',
    },
]