import axios from '@/plugins/axios'

export default {
  async getSignatures(query = '') {
    return axios.get(`/seller/signatures${query}`)
  },
  async getSignByOrder(orderId) {
    return axios.get(`/seller/orders/${orderId}/sign`)
  },
  async postSign(orderId, payload) {
    return axios.post(`/seller/orders/${orderId}/sign`, payload)
  },
}