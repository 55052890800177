import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/store'
import routes from './routes'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    document.getElementById('app').scrollIntoView();
    // Does not work but it's the vue way
    return {x: 0, y: 0}
  },
})

router.beforeEach(async (routeTo, routeFrom, next) => {
  const guarded = routeTo.matched.some((route) => route.meta.guard)
  if (guarded) {
    await store.dispatch('auth/fetchUser')
    const guard = routeTo.meta.guard
    if (guard.slice(0, 4) == 'auth') {
      if (!store.getters['auth/check']) {
        next({ name: 'login', query: { redirectFrom: routeTo.fullPath } })
      } else {
        if (!store.getters['auth/userEmailVerified']) {
          if (routeTo.name != 'user-verify') {
            next({ name: 'user-verify' })
          } else {
            next()
          }
        } else {
          let hasMerchant = store.getters['auth/hasMerchant'];
          if (!hasMerchant) {
            if (routeTo.name != 'merchant-update') {
              next({ name: 'merchant-update' })
            } else {
              next()
            }
          } else {
            const merchantInvalid = store.getters['auth/merchantInvalid'];
            const merchantVerify = store.getters['auth/merchantVerify'];
            if (merchantInvalid) {
              if ((routeTo.name == 'merchant-info') || (routeTo.name == 'merchant-update')) {
                next()
              } else {
                next({ name: 'merchant-update' })
              }
            }
            else if (merchantVerify) {
              if ((routeTo.name == 'merchant-info') || (routeTo.name == 'merchant-update')) {
                next()
              } else {
                next({ name: 'merchant-info' })
              }
            } else {
              let guardRoles = guard.split(':')
              if (guardRoles.length == 1) {
                next()
              } else {
                let roles = guardRoles[1].split(',')
                if (!store.getters['auth/hasRole'](roles)) {
                  next({ name: '404' })
                } else {
                  next()
                }
              }
            }
          }
        }
      }
    } else if (guard == 'guest') {
      if (store.getters['auth/check']) {
        next({ name: 'home' })
      } else next()
    } else next()
  } else next()
})

router.beforeResolve(async (routeTo, routeFrom, next) => {
  try {
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            if (args.length) {
              next(...args)
              reject(new Error('Redirected'))
            } else {
              resolve()
            }
          })
        } else {
          resolve()
        }
      })
    }
  } catch (error) {
    return
  }
  next()
})

export default router
